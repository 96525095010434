<template>
<v-container>
    <div class="ma-12">
        <v-expansion-panels>
            <v-expansion-panel v-for="(pedido,i) in pedidos" :key="i">
            <v-expansion-panel-header expand-icon="">
              <v-row class="px-6" style="line-height: 22px!important;">
                Pedido #{{pedido.id}} | 

                <span v-if="pedido.entrega=='pick up'" class="ml-1"> Pasa a Recoger a Sucursal</span>
                <span v-else class="ml-1"> Entrega a Domicilio</span>
                
                 <v-spacer dark></v-spacer>  <v-icon style="margin-right:10px; margin-top: -1px!important;">mdi-clock-fast</v-icon>
                  <span v-if="horas(pedido.created_at)>=0">{{horas(pedido.created_at)}} minutos</span>
                  <span v-else style="color:red;">{{horas(pedido.created_at)}} minutos</span>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-data-table
                  :headers="headerArray"
                  :items="pedido.platos"
                  hide-default-footer
                  item-key="name"
                  class="elevation-1"
                >


                <template v-slot:[`item.total`]="{ item }">
                  {{item.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
              </template>

              <template v-slot:[`item.atributos`]="{ item }">
                  <div v-if="item.atributos!=undefined" >
                    <div v-for="(taco,i) in item.atributos" :key="i">
                      - {{taco.cantidad}} {{nombreProducto(taco.producto)}} <span v-if="taco.extras!=[]">con {{taco.extras}} </span>
                    </div>
                  </div>
              </template>

              <template v-slot:[`item.producto`]="{ item }">
                  {{nombreProducto(item.producto)}}
              </template>


                </v-data-table>

                  <v-row class="mb-3 mt-6 mx-4">
                    <v-col cols="9">
                      <span>Datos de Contacto del Cliente({{pedido.nombre}}):</span>
                      <br>
                      <v-btn v-if="pedido.entrega!='pick up'" text v-bind:href="'https://www.google.com.mx/maps/place/' + pedido.entrega + '/@'" target="_blank" ><v-icon small class="mr-2"> mdi-map-marker</v-icon> {{pedido.entrega}}</v-btn>
                      <br>
                      <v-btn text><v-icon small class="mr-2"> mdi-phone</v-icon> Teléfono: {{pedido.telefono}}</v-btn>
                      <br>
                      <v-btn elevation="0" text @click="deleteItem(pedido)" color="black" style="margin-bottom:-30px!important;" class="px-0 mt-3" dark><v-icon small>mdi-close</v-icon> Cancelar Pedido</v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn elevation="0" v-if="pedido.status=='en curso'" @click="update(pedido, 'confirmado')" color="black" dark>Confirmar</v-btn>
                      <v-btn elevation="0" v-if="pedido.status=='confirmado'" @click="update(pedido, 'enviado')" color="black" dark>Enviado</v-btn>
                      <br>
                      <br>
                      <strong>Total: {{pedido.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                      <br>
                      <strong>Metodo de Pago: </strong> {{pedido.metodo}}
                    </v-col>
                  </v-row>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


        <!-- Dialogo confirmación de cancelación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro que deseas cancelar este pedido?
                    </div>
                    <v-btn class="mt-4" text color="error" @click="update(canceled, 'cancelado')">
                    Si
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    No
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
    </div>
</v-container>
</template>

<script>

import axios from "axios";

export default {


  props:{
        search:String
    },


  data () {
      return {
        sheet: false,
            canceled:'',
        headerArray:[
            { text: 'Cantidad', value: 'cantidad',},
            { text: 'Producto', value: 'producto',},
            { text: 'Extras', value: 'extras',},
            { text: 'Tacos', value: 'atributos',},
            { text: 'Subtotal', value: 'total',},
            { text: 'Comentario', value: 'comentario',},
        ]
      }
  },
    
    created(){
      this.$store.dispatch('pedido/getPedidos')
      this.$store.dispatch('producto/getProductos') 
      this.$store.dispatch('currentUser/getUser')
    },
    methods:{
      update(pedido, status){
        if(pedido==''){
          pedido = this.canceled
        }
        pedido.status = status
        axios.put("https://torstdio.site/api/v1/pedido/actualizar",Object.assign(pedido)).then(response=>{
            this.$store.dispatch('pedido/getPedidos')
            this.cancel()
        })
      },
      nombreProducto(id){
        return this.products.filter(producto=>producto.id == id).map(producto=>producto.nombre)[0]
      },
      horas(hora){
        var today = new Date();
        var Christmas = new Date(hora);
        var diffMs = (Christmas - today); // milliseconds between now & Christmas
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        
        return diffMins ;
      },
      cancel(){
            this.canceled = ''
            this.sheet = false
        },
        deleteItem (item) {
            this.canceled = item
            this.sheet = true
        },

    },
    computed:{
      currentUser:{
        get(){
          return this.$store.state.currentUser.user;
        }
      },
      pedidos:{
        get(){
          var pedidos = this.$store.state.pedido.pedidos.filter(pedido=>pedido.status == 'en curso' || pedido.status == 'confirmado').filter(pedido=>pedido.sucursal == this.currentUser.sucursal)

          if(this.search!=''){
            pedidos = pedidos.filter(pedido =>
              (pedido.entrega+'guero').toLowerCase().includes(this.search+'guero'.toLowerCase()) ||
              (pedido.telefono+'guero').toLowerCase().includes((this.search+'guero').toLowerCase()) ||
              (pedido.nombre+'guero').toLowerCase().includes((this.search+'guero').toLowerCase())
            )


          }else{
            return pedidos
          }
        }
      },
      products(){
        return this.$store.state.producto.productos
      },
    },
    mounted() {
        Echo.channel('test')
          .listen('Test', (e) => {
            this.$store.dispatch('pedido/getPedidos')
          })
        }
}
</script>

<style>

</style>